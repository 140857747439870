import { Box, ThemeProvider } from "@mui/system";
import React, { useEffect } from "react";
import { Route, Routes, useNavigate } from "react-router";
import ScrollToTop from "react-scroll-to-top";
import { Paths } from "./Resources/ListOfPaths/ListOfPaths";
import theme from "./theme/theme";
const App = () => {
  const navigate = useNavigate();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [navigate]);
  return (
    <Box>
      <ThemeProvider theme={theme}>
        <Box>
          <Routes>
            {Paths.map((path, i) => {
              return (
                <Route
                  exact
                  key={i}
                  path={path?.path}
                  element={path?.component}
                />
              );
            })}
          </Routes>
        </Box>
      </ThemeProvider>
      <ScrollToTop smooth />
    </Box>
  );
};

export default App;
