import { Box, Button, Typography } from '@mui/material'
import React from 'react'
import Vector from "../../Assets/logos/Vector.png";
import { useNavigate } from 'react-router';


const WhitePaperButton = ({title ,handelClick}) => {

 
  return (
    <Button
    sx={{
      backgroundColor: (theme) => theme.palette.primary.main,
      color: (theme) => theme.palette.white.main,
      borderRadius:"25px",
      padding:"7px 25px"
    }}
    onClick={handelClick}
  >
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        gap: "10px",
      }}
    >
      <img src={Vector} alt="" height={"20px"} />
      <Typography
        sx={{
          color: (theme) => theme.palette.white.main,
          fontFamily: "RoadRage",
          fontSize:"16px"
        }}
      >
       {title}
      </Typography>
    </Box>
  </Button>
  )
}

export default WhitePaperButton