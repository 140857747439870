import * as React from "react";
import Drawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import ListItem from "@mui/material/ListItem";
import { Box } from "@mui/system";
import { ListItemButton,  ListItemText, Typography } from "@mui/material";
import { useNavigate } from "react-router";
import { makeStyles } from "@mui/styles";
import Discord from "../../Assets/Icons/Discord.png";
import Twitter from "../../Assets/Icons/Twitter.png";
import { Link } from "react-router-dom";
import WhitePaperButton from "../Buttons/WhitePaperButton";
import CancelIcon from '@mui/icons-material/Cancel';
const useStyle = makeStyles((theme) => {
  return {
    active: {
      background: theme.palette.primary.main,
    },
    iconsContainer: {
      padding:"10px",
      display: "flex",
      gap: "20px",
      alignItems: "center",
     
    },
  };
});

export default function HeaderDrawer({ open, setOpen, list }) {
  const [navigationlinks, setnavigationlinks] = React.useState(null);
  const { active, iconsContainer } = useStyle();
  const navigate = useNavigate();

  const handleClick = (obj) => {
    let val = JSON.parse(JSON.stringify(navigationlinks));
    let activelinks =
      val &&
      val?.map((m) => {
        if (m.title === obj?.title) {
          m.selected = true;
        } else {
          m.selected = false;
        }
        return m;
      });
    setnavigationlinks(activelinks);
    setOpen(false);
    navigate(obj.link);
  };
  React.useEffect(() => {
    setnavigationlinks(list);
  }, [list]);

  const handelClick = ()=>{
    navigate("/whitepaper")
  }

  return (
    <Drawer
      anchor="right"
      open={open}
      onClose={() => {
        setOpen(false);
      }}
    >
      <Box
        sx={{
          width: "100%",
          maxWidth: "350px",
          minWidth: "250px",
          backgroundColor: "rgba(0 , 0 , 0 , 0.9)",
          height: "100vh",
        }}
      >
       <Box sx={{
        display:"flex",
        justifyContent:"flex-end",
        padding:"10px"
       }}>
          <CancelIcon size={"20px"} color="primary" onClick={
           () => {
              setOpen(false);
            }}/>
        </Box>
        <List sx={{ display: "flex", flexDirection: "column", gap: "5px" }}>
          {navigationlinks &&
            navigationlinks.map((m, i) => {
              return (
                <ListItem
                  key={i}
                  disablePadding
                  className={m.selected ? active : ""}
                >
                  <ListItemButton onClick={() => handleClick(m)}>
                    {/* <ListItemIcon>
                      <img
                        src={m?.icon}
                        alt={"icon"}
                        style={{ width: "20px" }}
                      />
                    </ListItemIcon> */}
                    <ListItemText
                    
                    >
                      <Typography  sx={{
                        color:m.selected ? "black": "red",
                        fontFamily:"RoadRage !important"
                      }}>

                      {m?.title}
                      </Typography>
                    </ListItemText>
                  </ListItemButton>
                </ListItem>
              );
            })}
        </List>
        <Box className={iconsContainer}>
          <Box
            sx={{
              paddingTop: "10px",
            }}
          >
            <Link
              // href={HeaderContent?.socialLinks?.facebook}
              target={"_blank"}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={Discord} alt="" width={"24px"} />
            </Link>
          </Box>
          <Box
            sx={{
              paddingTop: "10px",
            }}
          >
            <Link
              // href={HeaderContent?.socialLinks?.twitter}
              target={"_blank"}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={Twitter} alt="" width={"24px"} />
            </Link>
          </Box>
          <WhitePaperButton  title={"WhitePaper"} handelClick={handelClick}/>
        </Box>
      </Box>
    </Drawer>
  );
}
