import * as React from "react";
import Timeline from "@mui/lab/Timeline";
import TimelineItem from "@mui/lab/TimelineItem";
import TimelineSeparator from "@mui/lab/TimelineSeparator";
import TimelineConnector from "@mui/lab/TimelineConnector";
import TimelineContent from "@mui/lab/TimelineContent";

import { makeStyles } from "@mui/styles";
import timelineIcon from "../../Assets/logos/timelineIcon.png";
import theme from "../../theme/theme";
import { Box ,Typography} from "@mui/material";
import HeadingComponent from "../HeadingComponent/HeadingComponent";
import TaskAltIcon from '@mui/icons-material/TaskAlt';

const useStyles = makeStyles({
  container: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
    maxWidth: "90%",
    [theme.breakpoints.down("md")]: {
      maxWidth: "80%",
    },
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100%",
    },
  },

  roadMapVerticle: {
    display: "none",
    [theme.breakpoints.down("md")]: {
      display: "block",
    },
  },
  roadMapHorizontal: {
    display: "block",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
});

const timeLineArrayData = [
  {
    Question: "Q3 2023",
    ListItems: [
      {
        name: "Game concept Establishment",
        check: true,
      },
      {
        name: "Pitch Deck",
        check: true,
      },
      {
        name: "Detailed Documentation",
        check: true,
      },
      {
        name: "Game Design Storyboard",
        check: true,
      },
    ],
  },
  {
    Question: "Q4 2023",
    ListItems: [
      {
        name: "Game Development outlined",
        check: true,
      },
      {
        name: "Ecosystem Design",
        check: true,
      },
      {
        name: "Character Metamorphosis",
        check: true,
      },
      {
        name: "Private Seed Round ",
        check: true,
      },
    ],
  },
  {
    Question: "Q1 2024",
    ListItems: [
      {
        name: "Full game production (Beta)",
        check: true,
      },
      {
        name: "Team Expansion- 8 additional IT and marketing specialists",
        check: true,
      },
      {
        name: "OG character reveal for base levels",
        check: true,
      },
    ],
  },
  {
    Question: "Q1 2024",
    ListItems: [
      {
        name: "Website development",
        check: true,
      },
      {
        name: "On-chain migration",
      },
      {
        name: "Smart contract development",
      },
    ],
  },

  {
    Question: "Q2 2024",
    ListItems: [
      {
        name: "Public Round GameFi.org & Seedify Launchpad",
      },
      {
        name: "Internal Beta Launch to Public",
      },
      {
        name: "User Dashboard Release",
      },
      {
        name: "$BLOOD Token Launch Party ",
      },
    ],
  },

  {
    Question: "Q3 2024",
    ListItems: [
      {
        name: "Alpha Launch to Public",
      },
      {
        name: "Slay-to-Earn Program Launch",
      },
      {
        name: "Battle Arena Dashboard Live",
      },
      {
        name: " Social Media Growth Competition",
      },
    ],
  },

  {
    Question: "Q4 2024",
    ListItems: [
      {
        name: "Grand Opening $100k Tournament",
      },
      {
        name: " PVP Real Time challenging",
      },
      {
        name: " Player Avatar Integration",
      },
    ],
  },
];

const RoadMap = () => {
  const { container, subContainer, roadMapHorizontal, roadMapVerticle } =
    useStyles();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "center",
          }}
        >
          <HeadingComponent title={"Road Map"} />
        </Box>

        <Box className={roadMapHorizontal}>
          <RoadMapHorizontal />
        </Box>
        <Box className={roadMapVerticle}>
          <RoadMapVerticle />
        </Box>
      </Box>
    </Box>
  );
};

export default RoadMap;

const RoadMapVerticle = () => {
  return (
    <Timeline position="alternate">
      {timeLineArrayData?.map((item, idx) => {
        return (
          <TimelineItem>
            <TimelineSeparator>
              {idx + 1 === timeLineArrayData?.length ? null : (
                <img src={timelineIcon} alt="timelineIcon" width="20px" />
              )}

              {idx + 1 === timeLineArrayData?.length ? (
                <img src={timelineIcon} alt="timelineIcon" width="20px" />
              ) : (
                <>
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                  <TimelineConnector sx={{ background: "red" }} />
                  <TimelineConnector />
                </>
              )}
            </TimelineSeparator>
            <TimelineContent sx={{ width: "50px" }}>
              <Typography
                variant="h6"
                component="span"
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                }}
              >
                <Box>
                  <Typography
                    sx={{
                      fontFamily: "RoadRage",
                      fontSize: "13px",
                      width: "50px",
                      lineHeight: "1.5rem",
                      textAlign: "left",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    {item?.Question}
                  </Typography>
                </Box>
                <Box
                  sx={{
                    height: "50px ",
                    // backgroundColor:"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(231,24,24,1) 50%, rgba(0,0,0,1) 100%) !important",
                    border: "1px solid #701111",
                  }}
                ></Box>

                <Typography sx={{ textAlign: "justify" }}>
              
                     {item?.ListItems?.map((lisItem) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        {lisItem?.check ? (
                         <TaskAltIcon  sx={{
                            fontSize:"12px",
                            color:"#4BB543"
                          }}/>
                        ) : (
                          <Box
                            sx={{
                              paddingTop: "7px",
                              paddingLeft:lisItem?.check ?"": "4px"

                            }}
                          >
                            <Box
                              sx={{
                                width: "4px",
                                height: "4px",
                                backgroundColor: (theme) =>
                                  theme.palette.white.main,
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                        )}

                        <Typography
                          sx={{
                            fontSize: "13px",
                            color: (theme) => theme.palette.white.main,
                            textAlign: "left",
                            lineHeight: "18px",
                            paddingLeft:lisItem?.check ?"": "4px"
                          }}
                        >
                          {lisItem?.name}
                        </Typography>
                      </Box>
                    );
                  })}
                
                </Typography>
              </Typography>
            </TimelineContent>
          </TimelineItem>
        );
      })}
    </Timeline>
  );
};

const TopSection = [
  {
    Question: "Q3 2023",
    ListItems: [
      {
        name: "Game Concept Establishment",
        check: true,
      },
      {
        name: "Pitch Deck",
        check: true,
      },
      {
        name: "Detailed Documentation",
        check: true,
      },
      {
        name: "Game Design Storyboard",
        check: true,
      },
    ],
  },

  {
    Question: "Q4 2023",
    ListItems: [
      {
        name: "Game Development outlined",
        check: true,
      },
      {
        name: "Ecosystem Design",
        check: true,
      },
      {
        name: "Character Metamorphosis",
        check: true,
      },
      {
        name: "Private Seed Round ",
        check: true,
      },
    ],
  },

  {
    Question: "Q1 2024",
    ListItems: [
      {
        name: "Full game production (Beta)",
        check: true,
      },
      {
        name: "Team Expansion- 8 additional IT and marketing specialists",
        check: true,
      },
      {
        name: "OG character reveal for base levels",
        check: true,
      },
    ],
  },
];

const BottomSection = [
  {
    Question: "Q1 2024",
    ListItems: [
      {
        name: "Website development",
        check: true,
      },
      {
        name: "On-chain migration",
      },
      {
        name: "Smart contract development",
      },
    ],
  },
  {
    Question: "Q2 2024",
    ListItems: [
      {
        name: "Public Round GameFi.org & Seedify Launchpad",
      },
      {
        name: "Internal Beta Launch to Public",
      },
      {
        name: "User Dashboard Release",
      },
      {
        name: "$BLOOD Token Launch Party ",
      },
    ],
  },
  {
    Question: "Q3 2024",
    ListItems: [
      {
        name: "Alpha Launch to Public",
      },
      {
        name: "Slay-to-Earn Program Launch",
      },
      {
        name: "Battle Arena Dashboard Live",
      },
      {
        name: " Social Media Growth Competition",
      },
    ],
  },
  {
    Question: "Q4 2024",
    ListItems: [
      {
        name: "Grand Opening $100k Tournament",
      },
      {
        name: " PVP Real Time challenging",
      },
      {
        name: " Player Avatar Integration",
      },
    ],
  },
];

const RoadMapHorizontal = () => {
  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "right",
        }}
      >
        {TopSection?.map((item) => {
          return (
            <Box
            sx={{
              display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                gap: "10px",
                width: "100%",
            }}
          >
            <Box>
              <Typography
                sx={{
                  fontFamily: "RoadRage",
                  fontSize: "32px",
                  width: "85px",
                  lineHeight: "2rem",
                  textAlign: "left",
                  color: (theme) => theme.palette.white.main,
                }}
              >
                {item?.Question}
              </Typography>
            </Box>
            <Box
              sx={{
                height: "50px ",
                // backgroundColor:"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(231,24,24,1) 50%, rgba(0,0,0,1) 100%) !important",
                border: "1px solid #701111",
              }}
            ></Box>
            <Box>
           
                {item?.ListItems?.map((lisItem) => {
                  return (
                    <Box
                      sx={{
                        display: "flex",
                        justifyContent: "start",
                        alignItems: "start",
                        gap: "10px",
                      }}
                    >
                      {lisItem?.check ? (
                       <TaskAltIcon  sx={{
                          fontSize:"15px",
                          color:"#4BB543"
                        }}/>
                      ) : (
                        <Box
                          sx={{
                            paddingTop: "7px",
                            paddingLeft:lisItem?.check ?"": "6px"

                          }}
                        >
                          <Box
                            sx={{
                              width: "4px",
                              height: "4px",
                              backgroundColor: (theme) =>
                                theme.palette.white.main,
                              borderRadius: "50%",
                            }}
                          />
                        </Box>
                      )}

                      <Typography
                        sx={{
                          fontSize: "15px",
                          color: (theme) => theme.palette.white.main,
                          textAlign: "left",
                          lineHeight: "18px",
                          paddingLeft:lisItem?.check ?"": "6px"
                        }}
                      >
                        {lisItem?.name}
                      </Typography>
                    </Box>
                  );
                })}
            
            </Box>
          </Box>
          );
        })}
      </Box>
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          gap: "0px",
          padding: "0px",
        }}
      >
        {[...Array(7)]?.map((item, idx) => {
          return (
            <Box
              sx={{
                display: "flex",
                color: "white",
                width: "100%",
                alignItems: "center",
                gap: "0px",
                flex: 1,
              }}
            >
              <Box>
                <img src={timelineIcon} alt="timelineIcon" />
              </Box>
              {idx + 1 === 7 ? null : (
                <Box
                  sx={{
                    width: "100%",
                    border: "1px solid red",
                    borderStyle: "dashed",
                    backgroundColor: "white",
                    height: "0px",
                  }}
                ></Box>
              )}
            </Box>
          );
        })}
      </Box>

      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          textAlign: "right",
        }}
      >
        {BottomSection?.map((item) => {
          return (
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                width: "100%",
                gap: "10px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "RoadRage",
                    fontSize: "32px",
                    width: "85px",
                    lineHeight: "2rem",
                    textAlign: "left",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  {item?.Question}
                </Typography>
              </Box>
              <Box
                sx={{
                  height: "50px ",
                  // backgroundColor:"linear-gradient(0deg, rgba(0,0,0,1) 0%, rgba(231,24,24,1) 50%, rgba(0,0,0,1) 100%) !important",
                  border: "1px solid #701111",
                }}
              ></Box>
              <Box>
            
                  {item?.ListItems?.map((lisItem) => {
                    return (
                      <Box
                        sx={{
                          display: "flex",
                          justifyContent: "start",
                          alignItems: "start",
                          gap: "10px",
                        }}
                      >
                        {lisItem?.check ? (
                         <TaskAltIcon  sx={{
                            fontSize:"15px",
                            color:"#4BB543"
                          }}/>
                        ) : (
                          <Box
                            sx={{
                              paddingTop: "7px",
                              paddingLeft:lisItem?.check ?"": "6px"

                            }}
                          >
                            <Box
                              sx={{
                                width: "4px",
                                height: "4px",
                                backgroundColor: (theme) =>
                                  theme.palette.white.main,
                                borderRadius: "50%",
                              }}
                            />
                          </Box>
                        )}

                        <Typography
                          sx={{
                            fontSize: "15px",
                            color: (theme) => theme.palette.white.main,
                            textAlign: "left",
                            lineHeight: "18px",
                            paddingLeft:lisItem?.check ?"": "6px"
                          }}
                        >
                          {lisItem?.name}
                        </Typography>
                      </Box>
                    );
                  })}
               
              </Box>
            </Box>
          );
        })}
      </Box>
    </Box>
  );
};
