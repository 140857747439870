import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import VideoSlider from "../../VideoSlider/VideoSlider";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";
import theme from "../../../theme/theme";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "0px 20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "10px",
      width: "100%",
      maxWidth: "90%",  [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",

      },
    },
   

   
  };
});

const GamePlaySection = () => {
  const { container, subContainer } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
     
          <Box sx={{
            display:"flex",justifyContent:"center"
          }}>
           <HeadingComponent title={"Game Play"}/>
         
          </Box>
          <VideoSlider />
     
      </Box>
    </Box>
  );
};

export default GamePlaySection;
