


import React from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/effect-coverflow";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import { EffectCoverflow, Pagination } from "swiper";
import { CardMedia } from "@mui/material";

const  VideoSlider=()=> {
  const pagination = {
    clickable: true,
    renderBullet: function (index, className) {
      return '<span style="height:15px; width:15px ; background-color:red ; color:white  ; text-align:center " class="' + className + '">'  +  "</span>";
    },
  };
  return (
    <>
      <Swiper
      pagination={pagination}
        effect={"coverflow"}
        grabCursor={false}
        centeredSlides={false}
        slidesPerView={"auto"}
        coverflowEffect={{
          rotate: 25,
          stretch: 0,
          depth: 100,
          modifier: 1,
          slideShadows: true,
        }}
        // pagination={true}
        modules={[EffectCoverflow, Pagination]}
        className="mySwiper"
      >
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403672/4_y0k9rj.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
        <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403656/3_cokc50.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",
                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403665/2_oezlr7.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403671/11_p0pwkq.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403672/4_y0k9rj.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403656/3_cokc50.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403665/2_oezlr7.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403671/11_p0pwkq.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
        <SwiperSlide>
           <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702403672/4_y0k9rj.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  border: "2px solid grey",
                  margin: "0px",

                  width: "100%",
                }}
              />
        </SwiperSlide>
      </Swiper>
    </>
  );
}

export default VideoSlider