import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import theme from "../../../theme/theme";
import bg3 from "../../../Assets/images/bg3.png";
import Blood from "../../../Assets/images/Blood.png";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    bgImageBox: {
      backgroundImage: `url(${bg3})`,
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },

    mainBox: {
      display: "flex",
      justifyContent: "space-between",

      gap: "30px",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});

const AboutSection = () => {
  const { container, subContainer, mainBox, bgImageBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box className={mainBox}>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <HeadingComponent title={"About"} />
              <Typography
                sx={{
                  marginTop: "-20px",
                  paddingLeft: "35px",
                  maxWidth: "550px",
                  fontSize: "18px",
                  lineHeight: "22px",
                  color: (theme) => theme.palette.white.main,
                }}
              >
                Welcome to the bloodiest Player Versus Player death-match in
                Defi! BloodBath is a DAO driven ecosystem built from the ground
                up. Pick your favorite seiral killer and slash your way to
                victory.
              </Typography>
              <Box
                sx={{
                  paddingLeft: "35px",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: "650px",
                    fontSize: "18px",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Key Features :
                </Typography>
                <Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <CardMedia
                        component={"img"}
                        src={Blood}
                        sx={{
                          filter: "brightness(90%)",
                          width: "30px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Bank your $BLOOD with high yield staking
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <CardMedia
                        component={"img"}
                        src={Blood}
                        sx={{
                          filter: "brightness(90%)",
                          width: "30px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Winner-take-all death matches
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <CardMedia
                        component={"img"}
                        src={Blood}
                        sx={{
                          filter: "brightness(90%)",
                          width: "30px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Hosted tournaments
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      display: "flex",
                      alignItems: "center",
                      gap: "10px",
                    }}
                  >
                    <Box>
                      <CardMedia
                        component={"img"}
                        src={Blood}
                        sx={{
                          filter: "brightness(90%)",
                          width: "30px",
                        }}
                      />
                    </Box>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      SLAY TO EARN!
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404447/Freddy_pose_nhyktu.gif"} alt="" width="70%" />
              </Box>
            </Box>
          </Box>
          <Box
            className={mainBox}
            sx={{
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404498/Pennywise_pose2_1_h0ulkz.gif"} alt="" width="120%" />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                height: "inherit",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "flex-end",
                paddingLeft: "25px",
              }}
            >
              <HeadingComponent title={"Sneak Preview"} />

              <Typography
                sx={{
                  marginTop: "-15px",
                  paddingLeft: "35px",
                  maxWidth: "650px",
                  fontSize: "18px",
                  lineHeight: "22px",

                  color: (theme) => theme.palette.white.main,
                  [theme.breakpoints.down("md")]: {
                    marginTop: "10px",
                  },
                  [theme.breakpoints.down("sm")]: {
                    marginTop: "-10px",
                  },
                }}
              >
                Take a look at the carnage of the BloodBath Arena. Let the blood
                flow!
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default AboutSection;
