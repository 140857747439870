import Chart from "react-apexcharts";
import React from "react";
import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import bg3 from "../../Assets/images/002.png";
import HeadingComponent from "../HeadingComponent/HeadingComponent";

const useStyles = makeStyles({
  container: {
    display: "flex",
    padding: "10vh 0px",

    justifyContent: "center",
    backgroundImage: `url(${bg3})`,
    backgroundPosition: "right",
    backgroundPositionX: "80%",
    backgroundSize: "contain",
    backgroundRepeat: "no-repeat",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    width: "100%",
    maxWidth: "800px",
  },
});

const ApexChart = () => {
  const state = {
    series: [20, 19, 18, 17, 16, 15, 14, 13, 12],
    options: {
      chart: {
        width: 300,
        type: "polarArea",
      },

      labels: [
        "Bloody Reward",
        "Tournament Treasury",
        "Team & Development",
        "Staking",
        "Public",
        "Private R2 ",
        "Liquidity",
        "Speed",
        "Private R1",
      ],
      fill: {
        opacity: 1,
        colors: [
          "#450707",
          "#5c0a0a",
          "#740c0c",
          "#8b0e0e",
          "#a21111",
          "#d01616",
          "#e71818",
          "#ec4646",
          "#f17474",
        ],
      },
      stroke: {
        width: 1,
        colors: "rgba(231,24,24,0.8)",
      },
      yaxis: {
        show: false,
      },

      legend: {
        position: "right",
        show: false,
      },
      plotOptions: {
        polarArea: {
          rings: {
            strokeWidth: 0,
            strokeColor: "#e8e8e8",
          },
          spokes: {
            strokeWidth: 0,
            // connectorColors: '#e8e8e8'
          },
          polygons: {
            strokeWidth: 1,
            strokeColor: "#ffffff",
          },
        },
      },
      theme: {
        monochrome: {
          enabled: true,
          shadeTo: "light",
          shadeIntensity: 0.6,
        },
      },
    },
  };

  const { container, subContainer } = useStyles();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "end",
          }}
        >
          <Box
            sx={{
              display: "flex",
              justifyContent: "center",
              width: "100%",
            }}
          >
            <HeadingComponent title={"Blood Samples"} />
          </Box>

          <Box
            sx={{
              width: "100%",
              maxWidth: "800px",

              position: "relative",
            }}
          >
            <Box
              sx={{
                position: "absolute",
                top: "5%",
                right: "0%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#87CEEB",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Bloody Rewards (30%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "25%",
                right: "0%",
              }}
            >
              <Box sx={{ maxWidth: "100px" }}>
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#FFFF00",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Tournament Treasury (18%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "50%",
                right: "0%",
              }}
            >
              <Box sx={{ maxWidth: "100px" }}>
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#9F2B68",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Team & Development (17%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "5%",
                right: "10%",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#a21111",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Staking (10%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "0%",
                left: "48%",
              }}
            >
              <Box>
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#00FF00",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Public (7%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                bottom: "10%",
                left: "5%",
              }}
            >
              <Box sx={{}}>
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#0000FF",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Private R2 (6%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "55%",
                left: "0%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#00FF00",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Liquidity (5%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "38%",
                left: "10%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: "#FFC0CB",
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Speed (4%)
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                position: "absolute",
                top: "20%",
                left: "15%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  gap: "10px",
                }}
              >
                <Box
                  sx={{
                    width: "7px",
                    height: "7px",
                    borderRadius: "50%",
                    backgroundColor: (theme) => theme.palette.primary.main,
                  }}
                ></Box>
                <Typography
                  sx={{
                    fontSize: "18px",
                    fontFamily: "RoadRage !important",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  Private R1 (3%)
                </Typography>
              </Box>
            </Box>

            <Chart
              options={state.options}
              series={state.series}
              type="polarArea"
              width={"90%"}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ApexChart;
