import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import HeroSection from "../../Components/LandingPage/HeroSection/HeroSection";
import AboutSection from "../../Components/LandingPage/AboutSection/AboutSection";

import HowToPlaySection from "../../Components/LandingPage/HowToPlaySection/HowToPlaySection";
// import WatchTrailer from "../../Components/LandingPage/WatchTrailer/WatchTrailer";
import BloodTokenSection from "../../Components/LandingPage/BloodTokenSection/BloodTokenSection";
import OurTeamSection from "../../Components/LandingPage/OurTeamSection/OurTeamSection";
import FAQSection from "../../Components/LandingPage/FAQSection/FAQSection";
import RoadMap from "../../Components/RoadMap/RoadMap";
import GamePlaySection from "../../Components/LandingPage/GamePlay/GamePlay";
import bg4 from "../../Assets/images/BG4.png";

const useStyle = makeStyles((theme) => {
  return {
    container: {},
    LandingSection: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
  };
});

const LandingPage = () => {
  const { container, LandingSection } = useStyle();

  return (
    <Box className={container}>
      <Box className={LandingSection}>
        <Box>
          <HeroSection />
        </Box>
        <Box>
          <AboutSection />
        </Box>

        {/* <Box>
          <WatchTrailer />
        </Box> */}
        <Box>
          <HowToPlaySection />
        </Box>
        <Box>
          <BloodTokenSection />
        </Box>
        <Box>
          <Box
            sx={{
              backgroundImage: `linear-gradient(360deg, rgba(0, 0, 0, 0.9), rgba(0, 0, 0, 0.4)), url(${bg4})`,
              
              backgroundSize: "inherit",
              backgroundRepeat: "no-repeat",
              backgroundPositionX: "-30% ",
            }}
          >
            <GamePlaySection />

            <RoadMap />
          </Box>
        </Box>
        <Box>
          <OurTeamSection />
        </Box>

        <Box>
          <FAQSection />
        </Box>
      </Box>
    </Box>
  );
};

export default LandingPage;
