import Chart from "react-apexcharts";
import React from "react";

const RadialBarChart = () => {
  const state = {
    series: [60],
    options: {
        markers: {
            colors: ['#ffffff']
         },
      chart: {
        type: "radialBar",
        offsetY: -20,
        sparkline: {
          enabled: true,
        },
      },
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          track: {
            background: "",
            strokeWidth: "100%",
            margin: 5, // margin is in pixels
            dropShadow: {
              enabled: true,
              top: 2,
              left: 0,
              color: "#ffffff",
              opacity: 1,
              blur: 2,
            },
          },
          dataLabels: {
            name: {
              show: true,
              color: "#ffffff"
            },
            
            value: {
              offsetY: 10,
              fontSize: "18px",

            },
          },
        },
      },
      grid: {
        padding: {
          top: -10,
        },
      },
    
      fill: {
        type: "gradient",
        colors: [
          "#E71818",
        ],
        gradient: {
          shade: "light",
          shadeIntensity: 0.2,
          inverseColors: false,
          opacityFrom: 1,
          opacityTo: 1,
          stops: [0, 50, 53, 91],
        },
      },
      labels: ["Current Value 60%" ],
    },
  };

  return (
    <div id="chart">
      <Chart options={state.options} series={state.series} type="radialBar" />
    </div>
  );
};

export default RadialBarChart;
