import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import bg3 from "../../../Assets/images/bg3.png";

import OurTeamSlider from "../../Sliders/OurTeamSlider";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";
import theme from "../../../theme/theme";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",  [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",

      },
    },
    bgImageBox: {
      backgroundImage: `url(${bg3})`,
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      display: "flex",
      flexDirection: "column",
      gap: "25px",
    },

   
  };
});

const OurTeamSection = () => {
  const { container, subContainer,  bgImageBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box sx={{
            display:"flex",justifyContent:"center"
          }}>
           <HeadingComponent title={"Our Team"} />
          </Box>
          <OurTeamSlider />
        </Box>
      </Box>
    </Box>
  );
};

export default OurTeamSection;
