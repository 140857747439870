import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import theme from "../../../theme/theme";
import image62 from "../../../Assets/images/image62.png";
import Blood from "../../../Assets/images/Blood.png";
import bg3 from "../../../Assets/images/003.png";
import Group12 from "../../../Assets/logos/Group12.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";

const useStyle = makeStyles(() => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
      padding: "20px",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    bgImageBox: {
      //   backgroundImage: `url(${bg3})`,
      //   backgroundPosition: "right",
      //   backgroundSize: "contain",
      //   backgroundRepeat: "no-repeat",
    },

    mainBox: {
      display: "flex",
      justifyContent: "space-between",

      gap: "30px",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});

const TokenEcosystemAndDetail = () => {
  const { container, subContainer, mainBox, bgImageBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box
            className={mainBox}
            sx={{
              backgroundImage: `url(${bg3})`,
              backgroundPosition: "right",
              backgroundSize: "contain",
              backgroundRepeat: "no-repeat",
              padding: "20vh 0px",
              alignItems: "center",
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundImage: `url(${Blood})`,
                    backgroundPositionX: "0%",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: "125px",
                    display: "flex",
                    alignItems: "start",
                    [theme.breakpoints.down("sm")]: {
                      height: "125px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      paddingLeft: "50px",
                      paddingTop: "45px",
                      textAlign: "start",
                      lineHeight: "50px",
                      fontSize: "35px",
                      fontFamily: "RoadRage",
                      color: (theme) => theme.palette.white.main,
                      zIndex: 99,
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "25px",
                        paddingTop: "50px",
                        lineHeight: "2rem",
                      },
                    }}
                  >
                    $blood token <br />
                    ecosystem & revenue&#60;
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: "45px",
                  textAlign: "left",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: "500px",
                    color: (theme) => theme.palette.white.main,
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  The BloodBath ecosystem is designed as a self-sustaining,
                  revenue-generating community with a token staking mechanism.
                  Additonally, a totally transparent treasury reserve will be
                  utilized to continually expand the ecosystem along growth in
                  revenue opportunities.
                </Typography>

                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    padding: "7px 25px",
                    borderRadius: "13px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "RoadRage",
                    }}
                  >
                    Buy
                  </Typography>
                  <ArrowForwardIosIcon
                    color="white"
                    sx={{
                      fontSize: "13px",
                    }}
                  />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",

                width: "100%",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={image62} alt="image62" width="60%" />
              </Box>
            </Box>
          </Box>

          <Box
            className={mainBox}
            sx={{
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404496/Leather-face_pose_xhi1aa.gif"} alt="" width="90%" />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box>
                <HeadingComponent title={"Say To Earn!"} />
              </Box>
              <Typography
                sx={{
                  maxWidth: "500px",
                  color: (theme) => theme.palette.white.main,
                  fontSize: "18px",
                  paddingLeft: "35px",
                  marginTop: "-30px",
                  lineHeight: "22px",


                }}
              >
                BloodBath offers an entertaining and addictive game with both
                PVP and PVE modes. Players can use $BLOOD to enter one-on-one
                fights, tournaments, leagues, and special contest modes to win
                titles, prize pools, and the ultimate glory of the bloodiest
                badass in crypto gaming. And yes... There will be a bloody
                battle royale where only the most ruthless will survive!
              </Typography>
              <Box
                sx={{
                  paddingLeft: "35px",
                }}
              >
                {/* <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "5px 25px",
                  borderRadius: "13px",
                  width:"fit-content"
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.white.main,
                    textTransform: "capitalize",
                  fontSize: "14px",
                  fontFamily:"RoadRage"

                  }}
                >
                  Buy
                </Typography>
                <ArrowForwardIosIcon
                  color="white"
                  sx={{
                    fontSize: "13px",

                  }}
                />
              </Button> */}
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          top: "-150px",
          left: "0px",
        }}
      >
        <img src={Group12} alt="" width="200px" />
      </Box>
    </Box>
  );
};

export default TokenEcosystemAndDetail;
