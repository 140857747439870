import { Box } from "@mui/material";
import React from "react";


import Chuky from "../../Assets/Characters/Chuky.png";
import Fredy from "../../Assets/Characters/Fredy.png";
import GhostFace from "../../Assets/Characters/GhostFace.png";
import Jason from "../../Assets/Characters/Jason.png";
import LeatherFace from "../../Assets/Characters/LeatherFace.png";
import MikeMyers from "../../Assets/Characters/MikeMyers.png";
import Pennywise from "../../Assets/Characters/Pennywise.png";
import Pinhead from "../../Assets/Characters/Pinhead.png";
import elipsis from "../../Assets/images/Ellipse.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";
import Carousel from "react-elastic-carousel";

import "./styles.css";

// import required modules
import theme from "../../theme/theme";

const imagesList = [
  {
    src: Chuky,
    name:"Adam"
  },
  {
    src: Fredy,
    name:"Brian"

  },
  {
    src: GhostFace,
    name:"Jerry"

  },
  {
    src: Jason,
    name:"Abdul Razzaq"

  },
  {
    src: LeatherFace,
    name:"Muhammad anas"

  },
  {
    src: MikeMyers,
    name:"Muhammad Bilal"
    

  },
  {
    src: Pennywise,
    name:""

  },
  {
    src: Pinhead,
    name:""

  },
];

const breakPointsdeal = [
  { width: 320, itemsToShow: 2 },
  { width: 480, itemsToShow: 3 },
  { width: 640, itemsToShow: 4 },
  { width: 900, itemsToShow: 6 },
  // { width: 1200, itemsToShow: 2 },
];

const OurTeamSlider = () => {
  return (
    <>
      {/* <Swiper
        slidesPerView={6}
        spaceBetween={30}
        breakpoints={{
          // when window width is >= 320px
          300: {
            slidesPerView: 1,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          900: {
            slidesPerView: 4,
            spaceBetween: 20,
          },
          1200: {
            slidesPerView: 6,
            spaceBetween: 20,
          },
        }}
        freeMode={true}
        pagination={false}
        modules={[Pagination]}
        className="mySwiper"
      >
        {imagesList.map((image, index) => {
          return (
            <SwiperSlide key={index}>
              <Box
                key={index}
                sx={{
                  cursor: "pointer",
                  backgroundImage: `url(${elipsis})`,
                  backgroundPosition: "center",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",

                  width: "190px",
                  height: "230px",
                }}
              >
                <Box
                  sx={{
                    cursor: "pointer",
                    backgroundImage: `url(${image?.src})`,
                    backgroundPosition: "center",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    width: "190px",
                    height: "200px",
                  }}
                ></Box>
                <CardMedia
                component={"img"}
                loop
                muted
                controls
                src={image?.src}
                sx={{
                  filter: "brightness(90%)",
                 height:"320px"
                }}
              />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper> */}
      <Carousel
        enableAutoPlay={false}
        breakPoints={breakPointsdeal}
        pagination={false}
        showArrows={true}
        transitionMs={500}
        renderArrow={({ type, onClick }) => {
          console.log(type);
          return (
            <Box
              onClick={onClick}
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              {type === "PREV" ? (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    background: theme?.palette?.primary?.main,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosNewIcon
                    sx={{
                      color: "white ",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    background: theme?.palette?.primary?.main,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{
                      color: "white ",
                      fontSize: "16px",
                    }}
                  />{" "}
                </Box>
              )}
            </Box>
          );
        }}
      >
        {imagesList.map((image, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                color: "#fff",
                padding: "10px 10px",
              }}
            >
              {/* <div className={"imageWrapper"}>
              <div className={"overlay"} /> */}
              <SliderCard image={image} />
              {/* </div> */}
            </Box>
          );
        })}
      </Carousel>
    </>
  );
};

export default OurTeamSlider;
const SliderCard = ({ image }) => {
  return (
    <Box
      sx={{
        cursor: "pointer",
        backgroundImage: `url(${elipsis})`,
        backgroundPosition: "center",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",

        width: "190px",
        height: "230px",
      }}
    >
      <Box
        sx={{
          cursor: "pointer",
          backgroundImage: `url(${image?.src})`,
          backgroundPosition: "center",
          backgroundSize: "contain",
          backgroundRepeat: "no-repeat",
          width: "190px",
          height: "200px",
          display:"flex",
          justifyContent:"center",
          alignItems:"flex-end"
        }}
      >

     <Box sx={{
      color:"White",
      fontFamily: "RoadRage",
      fontSize:{md:"16px" , sm:"12px" , xs:"11px"},
      marginBottom:"-25px"
     }}>
      {image?.name}&#60;
      </Box> 
      </Box>
      
      {/* <CardMedia
      component={"img"}
      loop
      muted
      controls
      src={image?.src}
      sx={{
        filter: "brightness(90%)",
       height:"320px"
      }}
    /> */}
    </Box>
  );
};
