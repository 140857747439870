import Chart from "react-apexcharts"
import React from "react";
import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "0px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "900px",
    },
   

  };
});
const LineChart =()=> {
  const { container, subContainer } = useStyle();
   

     const state = {
      
        series: [{
            name: "Speed",
            data: [0, 80000,  82000, 84000, 86000, 88000, 90000, 92000 ,94000 ,96000]
        },
        {
          name: "Private-R1",
          data: [0, 70000, 72000, 74000, 76000, 78000, 80000, 82000, 84000 , 86000]
      },
        {
          name: "Private-R2",
          data: [0, 60000, 62000, 64000, 66000, 68000, 70000, 72000, 74000, 76000]
      },
      {
        name: "Public",
        data: [0, 50000, 52000, 54000, 56000, 58000, 60000, 62000, 64000 , 66000]
    },
    {
      name: "Team",
      data: [0, 40000, 42000, 44000, 46000, 48000, 50000, 52000, 54000, 56000]
  }],
        options: {
          chart: {
            height: 350,
            type: 'line',
            zoom: {
              enabled: false
            },
           
          },
          dataLabels: {
            enabled: false
          },
          stroke: {
            curve: 'straight',
            width:2
          },
          // title: {
          //   text: 'Product Trends by Month',
          //   align: 'left'
          // },
          grid: {
            borderColor: '#E61A1A',
            strokeDashArray: 7,
            row: {
              colors: ['transparent', 'transparent'], // takes an array which will be repeated on columns
              opacity: 0.5
            },
            column: {
              colors: ['transparent', 'transparent'],

            }
          
          },
          legend: {
            position: "right",
            show:true
          },
          xaxis: {
            categories: [0 , 10, 20,30,40,50,60,70,80,90,100],
            lines: {
              show: true,
              color:"red"
            }
          },
          
         
        },
      
      
      };
   

  

 
      return (
        

        <Box className={container}>
        <Box className={subContainer}>

<Chart options={state.options} series={state.series} type="line" height={350} />
</Box>
</Box>



      );
    }

    export default LineChart

