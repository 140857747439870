import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import TwitterIcon from "@mui/icons-material/Twitter";
import FacebookIcon from "@mui/icons-material/Facebook";
import { Fade } from "react-awesome-reveal";
import YouTubeIcon from '@mui/icons-material/YouTube';
import InstagramIcon from '@mui/icons-material/Instagram';

import FooterImage from "../../Assets/images/FooterBg.png";
import WhitePaperButton from "../Buttons/WhitePaperButton";
import theme from "../../theme/theme";
import { useNavigate } from "react-router";

const useStyle = makeStyles((theme) => {
  return {
    container: {
      width:"100%",
      backgroundImage: `url(${FooterImage})`,
      backgroundPosition: "center",
      backgroundSize: "cover",
      backgroundRepeat: "no-repeat",
      minHeight:"200px",
      maxHeight:"fit-content"
    },
    subContainer: {
      display: "flex",
      flexDirection:"column",
      justifyContent:"end",
      alignItems:"center",
      minHeight:"200px",
      width: "100%",
      padding: "20px 0px",
      [theme.breakpoints.down("md")]: {
        width: "auto",
      },
    },
  
    iconContainer: {
      height: "35px",
      width: "35px",
      display: "flex",
      borderRadius: "50%",
      alignItems: "center",
      justifyContent: "center",
      transition: "0.5s",
      background: "#fffdfd33",
      "&:hover": {
        
        background: theme.palette.grey[800],
        cursor: "pointer",
      },
    },
   
  };
});

const Footer = () => {
  const navigate =useNavigate()
  const {
    container,
    subContainer,
    iconContainer
   
  } = useStyle();

  const handelClick = ()=>{
    navigate("/whitepaper")
  }

  return (
    <Box className={container}>
     
      <Box className={subContainer}>
     
        <Box sx={{
          display:"flex",
          justifyContent:"center",
          alignItems:"center",
          gap:{md:"25px" , sm:"10px", xs:"10px" , lg:"25px"} ,
          [theme.breakpoints.down("sm")]:{
            flexDirection:"column",
            justifyContent:"center",
          alignItems:"center",
          }
        }}>
          
           
            

            <Box sx={{ display: "flex", alignItems: "center", gap: "15px" }}>
              <Fade direction="up" duration={1000}>
                <Box className={iconContainer}>
                
                    <YouTubeIcon  sx={{ fontSize: "22px" , color:theme=> theme.palette.white.main }} />
                 
                </Box>
              </Fade>

              <Fade direction="up" duration={1500}>
                <Box className={iconContainer}>
                 
                    <FacebookIcon  sx={{ fontSize: "22px" , color:theme=> theme.palette.white.main }} />
                
                </Box>
              </Fade>
              <Fade direction="up" duration={2000}>
                <Box className={iconContainer}>
                 
                    <InstagramIcon  sx={{ fontSize: "22px" , color:theme=> theme.palette.white.main }} />
                 
                </Box>
              </Fade>
              <Fade direction="up" duration={2000}>
                <Box className={iconContainer}>
                 
                    <TwitterIcon  sx={{ fontSize: "22px" , color:theme=> theme.palette.white.main }} />
                 
                </Box>
              </Fade>
            </Box>
            <Box>
              <Typography sx={{
                color: theme=> theme.palette.white.main,
                fontSize:"16px"
              }}>
              JOIN THE CONVERSATION
              </Typography>
            </Box>
            <Box>
            <WhitePaperButton title={"Join WhitePaper"} handelClick={handelClick}/>
            </Box>
          </Box>
        </Box>


          
         

       
       
     
    </Box>
  );
};

export default Footer;
