import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import theme from "../../../theme/theme";
import Piggy from "../../../Assets/images/Piggy.png";
import Blood from "../../../Assets/images/Blood.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";

const useStyle = makeStyles(() => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      position: "relative",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },

    mainBox: {
      display: "flex",
      justifyContent: "space-between",

      gap: "30px",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});

const PigyBankSection = () => {
  const { container, subContainer, mainBox, bgImageBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box
            className={mainBox}
            sx={{
              alignItems: "center",
              [theme.breakpoints.down("sm")]: {
                flexDirection: "column-reverse",
              },
            }}
          >
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img
                  src={Piggy}
                  alt=""
                  style={{
                    width: "100%",
                    maxWidth: "325px",
                  }}
                />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
              }}
            >
              <Box>
                <Box
                  sx={{
                    backgroundImage: `url(${Blood})`,
                    backgroundPositionX: "0%",
                    backgroundSize: "contain",
                    backgroundRepeat: "no-repeat",
                    height: "125px",
                    display: "flex",
                    alignItems: "start",
                    [theme.breakpoints.down("sm")]: {
                      height: "125px",
                    },
                  }}
                >
                  <Typography
                    sx={{
                      paddingLeft: "60px",
                      paddingTop: "45px",
                      textAlign: "start",
                      lineHeight: "50px",
                      fontSize: "35px",
                      fontFamily: "RoadRage",
                      color: (theme) => theme.palette.white.main,
                      zIndex: 99,
                      [theme.breakpoints.down("sm")]: {
                        fontSize: "25px",
                        paddingTop: "50px",
                        lineHeight: "2rem",
                      },
                    }}
                  >
                    $Bank Your <br />
                    $Blood By Staking&#60;
                  </Typography>
                </Box>
              </Box>
              <Box
                sx={{
                  paddingLeft: "50px",
                  textAlign: "left",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: "500px",
                    color: (theme) => theme.palette.white.main,
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  Enhance your returns through staking your $BLOOD tokens and
                  earn rewards when you are not even fighting in the BloodBath
                  Arena. Redeem your tokens while others bleed out in mortal
                  death matches!
                </Typography>

                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    padding: "7px 25px",
                    borderRadius: "13px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "RoadRage",
                    }}
                  >
                    Stake
                  </Typography>
                  <ArrowForwardIosIcon
                    color="white"
                    sx={{
                      fontSize: "13px",
                    }}
                  />
                </Button>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default PigyBankSection;
