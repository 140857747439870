import { Box, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import YouTubeIcon from "@mui/icons-material/YouTube";
import TwitterIcon from "@mui/icons-material/Twitter";
import InstagramIcon from "@mui/icons-material/Instagram";
import FacebookIcon from "@mui/icons-material/Facebook";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      padding: "0px 20px 40px 20px",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    mainBox: {
      display: "flex",
      justifyContent: "center",
      paddingLeft: "40%",
      alignItems: "center",
      gap: "50px",
      flexWrap: "wrap",
      padding: "0px 20px",
      [theme.breakpoints.down("md")]: {
        paddingLeft: "20%",
        gap: "20px",
      },
    },
    iconBox: {
      width: "35px",
      height: "35px",
      backgroundColor: theme?.palette.grey[800],
      borderRadius: "50%",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
      cursor: "pointer",
      "&:hover": {
        transform: "scale(1.15)",
      },
    },
  };
});

const WatchTrailer = () => {
  const { container, subContainer, iconBox, mainBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box>
          <Box className={mainBox}>
            {/* <Button
              sx={{
                border: (theme) => `2px solid ${theme?.palette.white.main}`,
                padding: "5px 20px",
                color: (theme) => theme?.palette.white.main,
                borderRadius: "10px",
                fontSize: "18px",
              }}
            >
              Watch Trailer
            </Button> */}
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                gap: "10px",
              }}
            >
              <Box className={iconBox}>
                <YouTubeIcon
                  sx={{
                    color: (theme) => theme?.palette.white.main,
                    fontSize: "22px",
                  }}
                />
              </Box>
              <Box className={iconBox}>
                <FacebookIcon
                  sx={{
                    color: (theme) => theme?.palette.white.main,
                    fontSize: "22px",
                  }}
                />
              </Box>
              <Box className={iconBox}>
                <InstagramIcon
                  sx={{
                    color: (theme) => theme?.palette.white.main,
                    fontSize: "22px",
                  }}
                />
              </Box>
              <Box className={iconBox}>
                <TwitterIcon
                  sx={{
                    color: (theme) => theme?.palette.white.main,
                    fontSize: "22px",
                  }}
                />
              </Box>
            </Box>
            <Typography
              sx={{
                color: (theme) => theme?.palette.white.main,
                fontSize: "16px ",
              }}
            >
              JOIN THE CONVERSATION
            </Typography>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default WatchTrailer;
