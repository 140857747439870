import * as React from "react";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import { makeStyles } from "@mui/styles";
import { Box } from "@mui/material";

const useStyles = makeStyles({
  container: {
    padding: "20px",
    display: "flex",
    justifyContent: "center",
  },
  subContainer: {
    display: "flex",
    flexDirection: "column",
    gap: "50px",
    width: "100%",
    maxWidth: "900px",
    padding: "20px 0px",
  },
});

const rowData = [
  {
    Allocations: "Speed",
    Tokens: "40,000,000",
    Share: "4%",
    TGERelease: "7.5%",
    Cliff: "8",
    Vesting: "12",
  },
  {
    Allocations: "Private-R1",
    Tokens: "30,000,000 ",
    Share: "3%",
    TGERelease: "10%",
    Cliff: "5",
    Vesting: "8",
  },
  {
    Allocations: "PRIVATE-R2",
    Tokens: "60,000,000",
    Share: "6%",
    TGERelease: "10%",
    Cliff: "4",
    Vesting: "6",
  },
  {
    Allocations: "PUBLIC",
    Tokens: "70,000,000",
    Share: "7%",
    TGERelease: "15%",
    Cliff: "0",
    Vesting: "3",
  },
  {
    Allocations: "TEAM& DEVELOPMENT",
    Tokens: "170,000,000",
    Share: "17% ",
    TGERelease: "0%",
    Cliff: "8",
    Vesting: "24",
  },
  {
    Allocations: "STAKING",
    Tokens: "100,000,000",
    Share: "10%",
    TGERelease: "0%",
    Cliff: "0",
    Vesting: "12",
  },
  {
    Allocations: "BLOODY REWARDS  30% 1% 0 72",
    Tokens: "300,000,000",
    Share: "30%",
    TGERelease: "1%",
    Cliff: "0",
    Vesting: "72",
  },

  {
    Allocations: "TOURNAMENT TREASURY",
    Tokens: "180,000,000",
    Share: "18%",
    TGERelease: "0%",
    Cliff: "0",
    Vesting: "12",
  },
  {
    Allocations: "LIQUIDITY",
    Tokens: "50,000,000",
    Share: "5%",
    TGERelease: "40%",
    Cliff: "0",
    Vesting: "3",
  },
];
const BasicTable = () => {
  const { container, subContainer } = useStyles();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <TableContainer
          component={Paper}
          sx={{
            boxShadow: " 0px 1px 5px 4px rgba(89,2,2,0.59)",
            border: "none",
          }}
        >
          <Table
            sx={{ minWidth: 650, border: "none " }}
            aria-label="simple table"
          >
            <TableHead
              sx={{
                border: "none ",
              }}
            >
              <TableRow
                sx={{
                  "& td, & th": { border: 0 },
                  background:
                    "linear-gradient(1deg, rgba(89,2,2,1) 0%, rgba(0,0,0,1) 45%)",
                  border: "none ",

                }}
              >
                <TableCell
                  sx={{
                    color: (theme) => theme.palette.white.main,
                    fontSize:"16px"
                  }}
                >
                  Allocations
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: (theme) => theme.palette.white.main,
                       fontSize:"16px",
                  }}
                >
                  Tokens
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: (theme) => theme.palette.white.main,
                       fontSize:"16px",
                  }}
                >
                  Share
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: (theme) => theme.palette.white.main,
                       fontSize:"16px",
                  }}
                >
                  TGE Release
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: (theme) => theme.palette.white.main,
                       fontSize:"16px",
                  }}
                >
                  Cliff (Months)
                </TableCell>
                <TableCell
                  align="right"
                  sx={{
                    color: (theme) => theme.palette.white.main,
                       fontSize:"16px",
                    border: 0,
                  }}
                >
                  Vesting (M0nths)
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {rowData?.map((data) => {
                return (
                  <TableRow
                    sx={{
                      "& td, & th": { border: 0 },
                      background:
                        "linear-gradient(1deg, rgba(89,2,2,1) 0%, rgba(0,0,0,1) 45%)",
                    }}
                  >
                    <TableCell
                      component="th"
                      scope="row"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.Allocations}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.Tokens}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.Share}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.TGERelease}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.Cliff}
                    </TableCell>
                    <TableCell
                      align="right"
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize:"14px"
                      }}
                    >
                      {data?.Vesting}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Box>
    </Box>
  );
};
export default BasicTable;
