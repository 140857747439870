import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import theme from "../../../theme/theme";
import fightImage from "../../../Assets/images/image.png";
import BloodLines from "../../../Assets/images/BloodLines.png";
import bg1 from "../../../Assets/images/001.png";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";
import WatchTrailer from "../WatchTrailer/WatchTrailer";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    videoBox: {
      display: "flex",
      width: "100%",
      justifyContent: "space-around",
      gap: "10px",
      overflow: "hidden",

      [theme?.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});

const HowToPlaySection = () => {
  const { container, subContainer, videoBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: "15vh",
            backgroundImage: `url(${bg1})`,
            backgroundPosition: "center",
            backgroundSize: "contain",
            backgroundRepeat: "no-repeat",
            position: "relative",
          }}
        >
          <Box
            sx={{
              position: "absolute",
              top: "25%",
              left: 0,
            }}
          >
            <CardMedia
              component={"img"}
              src={BloodLines}
              sx={{
                filter: "brightness(70%)",

                width: "200px",
              }}
            />
          </Box>
          <Box
            sx={{
              position: "absolute",
              display: "block",

              top: "50%",
              right: "0%",
              [theme.breakpoints.down("sm")]: {
                display: "none",
              },
            }}
          >
            <CardMedia
              component={"img"}
              src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404455/Ghostface_Gif_seweoo.gif"}
              sx={{
                filter: "brightness(70%)",

                width: "300px",
              }}
            />
          </Box>
          <Box >
          <WatchTrailer />

          <Box className={videoBox}>
            <Box
              sx={{
                flex: "1",
                height: "100%",
                overflow: "hidden",
                margin: "0px",
                display: "flex",
                justifyContent: "center",
              }}
            >

              <CardMedia
                component="iframe"
                loop
                muted
                controls
                src={"https://www.youtube.com/embed/OKSI434N-Wo?si=E11j03Cnn5raGLAi"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  boxShadow: "0px 1px 5px 4px rgba(89,2,2,0.99)",
                  margin: "0px",

                  width: "85%",
                  height:"450px",
                  [theme?.breakpoints.down("sm")]:{
                  height:"250px"

                  }
                }}
              />
            </Box>
            {/* <Box
              sx={{
                flex: "1",
                height: "100%",
                width: "100%",
                overflow: "hidden",
                margin: "0px",
                display: "flex",
                justifyContent: "center",
                
              }}
            >
              <CardMedia
                component={"video"}
                loop
                muted
                controls
                src={Video2}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  boxShadow: "0px 1px 5px 4px rgba(89,2,2,0.99)",
                  margin: "0px",

                  width: "90%",
                }}
              />
            </Box> */}
          </Box>
            </Box>
         
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              gap: "25px",
            }}
          >
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <HeadingComponent title={"How to Play"} />
              <Typography
                sx={{
                  fontSize: "18px",
                  color: (theme) => theme.palette.white.main,
                  maxWidth: "600px",
                  textAlign: "center",
                  lineHeight: "22px",
                }}
              >
                Join the BloodBath community! Connect your wallet, buy your
                $BLOOD tokens, find a challenger, set the wager, and let the
                blood flow!
              </Typography>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-around",
                width: "100%",
                gap: "30px",
                alignItems: "center",
                [theme.breakpoints.down("sm")]: {
                  flexDirection: "column",
                },
              }}
            >
              <Box
                sx={{
                  flex: "1.5",
                }}
              >
                <img src={fightImage} alt="" width="90%" />
              </Box>
              <Box
                sx={{
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  gap: "5px",
                }}
              >
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "RoadRage",
                        fontSize: "28px",
                        color: (theme) => theme.palette.white.main,
                      }}
                    >
                      1. Download
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    IOS and Windows compatible.
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "RoadRage",
                        fontSize: "28px",
                        color: (theme) => theme.palette.white.main,
                      }}
                    >
                      2. Select Avatar & Arena
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    Choose your villain and set your wager.
                  </Typography>
                </Box>
                <Box>
                  <Box>
                    <Typography
                      sx={{
                        fontFamily: "RoadRage",
                        fontSize: "28px",
                        color: (theme) => theme.palette.white.main,
                      }}
                    >
                      3. Play
                    </Typography>
                  </Box>
                  <Typography
                    sx={{
                      fontSize: "18px",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    Go head-to-head in bloody combat in a winner take all battle
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default HowToPlaySection;
