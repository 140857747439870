import { Box,  Typography } from "@mui/material";
import React from "react";
import Blood from "../../Assets/images/Blood.png";
import theme from "../../theme/theme";

const HeadingComponent = ({title}) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${Blood})`,
        backgroundPositionX:"0%",
        backgroundSize: "contain",
        backgroundRepeat: "no-repeat",
        height:"125px",
        display:"flex",
        alignItems:"center",
        [theme.breakpoints.down("sm")]:{
          height:"125px",


          }
      }}
    >
     
      <Typography
        sx={{
            paddingLeft:"50px",
            paddingTop:"30px",

          fontSize: "35px",
          fontFamily: "RoadRage",
          color: theme=> theme.palette.white.main,
          zIndex:99,
          [theme.breakpoints.down("sm")]:{
          fontSize: "25px",
          paddingTop:"20px",

          }


        }}
      >
       {title}&#60;
      </Typography>
    </Box>
  );
};

export default HeadingComponent;
