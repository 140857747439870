import { Box, CardMedia, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import theme from "../../../theme/theme";
import bg3 from "../../../Assets/images/002.png";
import Blood from "../../../Assets/images/Blood.png";
import WhitePaperButton from "../../Buttons/WhitePaperButton";
import CharactersSlider from "../../Sliders/CharactersSlider";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";
import { useNavigate } from "react-router";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    bgImageBox: {
      backgroundImage: `url(${bg3})`,
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },

    mainBox: {
      display: "flex",
      justifyContent: "space-between",

      gap: "30px",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});

const BloodTokenSection = () => {
  const navigate  = useNavigate()
  const { container, subContainer, mainBox, bgImageBox } = useStyle();
const handelClick = ()=>{
  navigate(0)
}
  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box className={mainBox}>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  display: "flex",
                  justifyContent: "center",
                }}
              >
                <img src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404481/Jason_pose3_sg7srs.gif"} alt="" width="75%" />
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                height: "inherit",
                flexDirection: "column",
                alignItems: "start",
                justifyContent: "center",
                paddingLeft: "25px",
                gap: "10px",
              }}
            >
              <Box>
                <Typography
                  sx={{
                    fontFamily: "RoadRage",
                    fontSize: "35px",
                    color: (theme) => theme.palette.white.main,
                  }}
                >
                  $Blood Token&#60;
                </Typography>
              </Box>

              <Box
                sx={{
                  maxWidth: "500px",
                  fontSize: "18px",
                }}
              >
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={Blood}
                      sx={{
                        filter: "brightness(90%)",
                        width: "30px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      fontSize: "18px",
                      fontWeight: "light",
                    }}
                  >
                    Bank your $BLOOD with staking
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={Blood}
                      sx={{
                        filter: "brightness(90%)",
                        width: "30px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      fontSize: "18px",
                      fontWeight: "light",
                    }}
                  >
                    Slay to Earn
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={Blood}
                      sx={{
                        filter: "brightness(90%)",
                        width: "30px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      fontSize: "18px",
                      fontWeight: "thin",
                    }}
                  >
                    BLOODY DAO
                  </Typography>
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    gap: "10px",
                  }}
                >
                  <Box>
                    <CardMedia
                      component={"img"}
                      src={Blood}
                      sx={{
                        filter: "brightness(90%)",
                        width: "30px",
                      }}
                    />
                  </Box>
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      fontSize: "18px",
                      fontWeight: "light",
                    }}
                  >
                    Tokenomics
                  </Typography>
                </Box>
              </Box>
              <WhitePaperButton title={"$Blood Analysis"} handelClick={handelClick} />
            </Box>
          </Box>
          <Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                flexDirection: "column",
                paddingBottom: "50px",
              }}
            >
              <HeadingComponent title={"Game Characters"} />
              <Typography
                sx={{
                  fontSize: "18px",
                  maxWidth: "600px",
                  textAlign: "center",
                  lineHeight: "22px",

                  color: (theme) => theme.palette.white.main,
                }}
              >
                Start with you favorite Serial Killers as you stab and slash
                your way to victory. Challenge the computer to unlock new levels
                and new Killers.
              </Typography>
            </Box>

            <CharactersSlider />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BloodTokenSection;
