import { Box, CardMedia, Typography, Button, Grid } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import coingif from "../../../Assets/Gifs/coingif.gif";
import BackgroundImage from "../../../Assets/images/BackgroundImage.png";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../theme/theme";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
      backgroundImage: `url(${BackgroundImage})`,
      backgroundPosition: "right",
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",
      alignItems: "center",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  };
});

const tokenDetail = [
  {
    title: "$0.01",
    desc: "Value at Private Sale",
  },
  {
    title: "$0.0175",
    desc: "Value at Seed Round",
  },
  {
    title: "$0.04",
    desc: "Value on LaunchPad",
  },
  {
    title: "420M",
    desc: "Token Offered in Public Sale",
  },
  {
    title: "2.78B",
    desc: "Limited Token Supply",
  },
  {
    title: "31.5M",
    desc: "Circulating Token Supply",
  },
  {
    title: "BNB Network",
    desc: "Blockchain Network",
  },
  {
    title: "$BLOOD",
    desc: "Token Symbol",
  },
];

const TokensHeroSection = () => {
  const { container, subContainer } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
            maxWidth: "1100px",
            paddingTop: "150px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Box
            sx={{
              width: "100%",
              flex: 1,
              display: "flex",
              flexDirection: "column",
              gap: "20px",
            }}
          >
            <Box>
            <HeadingComponent title={"$Blood Token"} />

              {/* <Typography
                sx={{
                  fontFamily: "RoadRage",
                  fontSize: "35px",
                  textAlign: "left",
                  color: (theme) => theme.palette.white.main,
                  [theme.breakpoints.down("sm")]: {
                    fontSize: "30px",
                  },
                }}
              >
                $Blood Token
              </Typography> */}
            </Box>
            <Box sx={{
              paddingLeft:"45px"
            }}>
              <Typography
                sx={{
                  color: (theme) => theme.palette.white.main,
                  fontSize: "18px",
                  maxWidth: "450px",
                  lineHeight: "22px",
                }}
              >
                These tokenomics are just bloody awesome! Our community is built
                on a sustainable game model that is built to both earn profits
                and increase the value of your invested capital.
              </Typography>
            </Box>
            <Box sx={{
              paddingLeft:"45px"
            }}>
              <Button
                sx={{
                  backgroundColor: (theme) => theme.palette.primary.main,
                  display: "flex",
                  alignItems: "center",
                  gap: "5px",
                  padding: "10px 25px",
                  borderRadius: "13px",
                }}
              >
                <Typography
                  sx={{
                    color: (theme) => theme.palette.white.main,
                    textTransform: "capitalize",
                    fontSize: "14px",
                    fontFamily: "RoadRage",
                  }}
                >
                  Enter WhiteList
                </Typography>
                <ArrowForwardIosIcon
                  color="white"
                  sx={{
                    fontSize: "14px",
                  }}
                />
              </Button>
            </Box>
          </Box>
          <Box>
            <CardMedia
              component={"img"}
              loop
              muted
              controls
              src={coingif}
              sx={{
                filter: "brightness(90%)",
                height: "300px",
                width: "100%",
                maxWidth: "300px",
              }}
            />
          </Box>
        </Box>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "start",
            width: "100%",
            maxWidth: "900px",

            paddingTop: "150px",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
              alignItems: "center",
            },
          }}
        >
          <Grid container spacing={5}>
            {tokenDetail?.map((token) => {
              return (
                <Grid item md={3} sm={3} xs={6}>
                  <Typography
                    sx={{
                      fontSize: "28px",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    {token?.title}
                  </Typography>
                  <Typography
                    sx={{
                      fontSize: "12px",
                      color: (theme) => theme.palette.white.main,
                    }}
                  >
                    {token?.desc}
                  </Typography>
                </Grid>
              );
            })}
          </Grid>
        </Box>
      </Box>
    </Box>
  );
};

export default TokensHeroSection;
