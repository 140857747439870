import { Box, Button, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import bg3 from "../../../Assets/images/001.png";
import RadialBarChart from "../../RadialBarChart/RadiaBarChart";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import theme from "../../../theme/theme";
import LinearProgress, {
  linearProgressClasses,
} from "@mui/material/LinearProgress";
import { styled } from "@mui/material/styles";
import HeadingComponent from "../../HeadingComponent/HeadingComponent";
const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    bgImageBox: {
      backgroundImage: `url(${bg3})`,
      backgroundSize: "contain",
      backgroundRepeat: "no-repeat",
      backgroundPositionX: "90%",
    },
    mainBox: {
      display: "flex",
      justifyContent: "space-between",

      gap: "30px",
      textAlign: "center",
      width: "100%",
      [theme.breakpoints.down("sm")]: {
        flexDirection: "column",
      },
    },
  };
});
const BorderLinearProgress = styled(LinearProgress)(({ theme }) => ({
  height: 10,
  borderRadius: 5,
  [`&.${linearProgressClasses.colorPrimary}`]: {
    backgroundColor:
      theme.palette.grey[theme.palette.mode === "light" ? 200 : 800],
  },
  [`& .${linearProgressClasses.bar}`]: {
    borderRadius: 5,
    backgroundColor: theme.palette.mode === "light" ? "#1a90ff" : "#308fe8",
  },
}));
const BloodyDaoSection = () => {
  const { container, subContainer, bgImageBox, mainBox } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box className={bgImageBox}>
          <Box className={mainBox}>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "0px",
              }}
            >
              <Box>
                <HeadingComponent title={"Bloody Dao"} />
              </Box>
              <Box
                sx={{
                  paddingLeft: "35px",

                  textAlign: "left",
                  flex: "1",
                  display: "flex",
                  flexDirection: "column",
                  gap: "30px",
                }}
              >
                <Typography
                  sx={{
                    maxWidth: "500px",
                    color: (theme) => theme.palette.white.main,
                    fontSize: "18px",
                    lineHeight: "22px",
                  }}
                >
                  The BloodBath ecosystem not only promises to be the best P2E &
                  PVP crypto game on the market, but also the most transparent.
                  Empower your investment and the help make the most critical
                  decisions on the direction of the project through the $BLOOD
                  DAO.
                </Typography>
                <Button
                  sx={{
                    backgroundColor: (theme) => theme.palette.primary.main,
                    display: "flex",
                    alignItems: "center",
                    gap: "5px",
                    padding: "7px 25px",
                    borderRadius: "13px",
                    width: "fit-content",
                  }}
                >
                  <Typography
                    sx={{
                      color: (theme) => theme.palette.white.main,
                      textTransform: "capitalize",
                      fontSize: "16px",
                      fontFamily: "RoadRage",
                    }}
                  >
                    Vote
                  </Typography>
                  <ArrowForwardIosIcon
                    color="white"
                    sx={{
                      fontSize: "13px",
                    }}
                  />
                </Button>
              </Box>
            </Box>
            <Box
              sx={{
                textAlign: "left",
                flex: "1",
                display: "flex",
                flexDirection: "column",
                gap: "30px",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                  display: "flex",
                  flexDirection: "column",
                  gap: "10px",
                }}
              >
                <RadialBarChart />
                <Box
                  sx={{
                    maxWidth: "300px",
                    textAlign: "center",
                    paddingLeft: "100px",
                    [theme.breakpoints.down("sm")]: {
                      paddingLeft: "25px",
                    },
                  }}
                >
                  {" "}
                  <Box
                    sx={{
                      maxWidth: "300px",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      paddingBottom: "20px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Your Vote
                    </Typography>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      68%
                    </Typography>
                  </Box>
                  <BorderLinearProgress variant="determinate" value={68} />
                </Box>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    gap: "25px",
                    flexWrap: "wrap",
                  }}
                >
                  <Box
                    sx={{
                      backgroundColor: "#0D0D0D !important",
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContant: "center",
                      alignItems: "center",
                      width: "fit-content",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Voted For
                    </Typography>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                        fontWeight: "semibold",
                      }}
                    >
                      28%
                    </Typography>
                    <Typography
                      sx={{
                        color: "#80000A",
                        fontSize: "18px",
                      }}
                    >
                      0x1e76......E8E
                    </Typography>
                  </Box>
                  <Box
                    sx={{
                      backgroundColor: "#0D0D0D !important",
                      padding: "10px",
                      display: "flex",
                      flexDirection: "column",
                      justifyContant: "center",
                      alignItems: "center",
                      width: "fit-content",
                      borderRadius: "15px",
                    }}
                  >
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                      }}
                    >
                      Voted For
                    </Typography>
                    <Typography
                      sx={{
                        color: (theme) => theme.palette.white.main,
                        fontSize: "18px",
                        fontWeight: "semibold",
                      }}
                    >
                      72%
                    </Typography>
                    <Typography
                      sx={{
                        color: "#80000A",

                        fontSize: "18px",
                      }}
                    >
                      0x1e76......E8E
                    </Typography>
                  </Box>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default BloodyDaoSection;
