import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";

import TokensHeroSection from "../../Components/Tokens/TokensHeroSection/TokensHeroSection";
import TokenEcosystemAndDetail from "../../Components/Tokens/TokenEcosystemAndDetail/TokenEcosystemAndDetail";
import PigyBankSection from "../../Components/Tokens/PigyBankSection/PigyBankSection";

import ApexChart from "../../Components/HeadingChart/HeadingChart";
import BasicTable from "../../Components/Table/Table";
import LineChart from "../../Components/LineChart/LineChart";
import BloodyDaoSection from "../../Components/Tokens/BloodyDaoSection/BloodyDaoSection";

const useStyle = makeStyles((theme) => {
  return {
    container: {},
    LandingSection: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
  };
});

const TokensPage = () => {
  const { container, LandingSection } = useStyle();

  return (
    <Box className={container}>
      <Box className={LandingSection}>
        <Box>
          <TokensHeroSection />
        </Box>
        <Box>
          <TokenEcosystemAndDetail />
        </Box>
        <Box>
          <BloodyDaoSection />
        </Box>
        <Box>
          <PigyBankSection />
        </Box>
        <Box>
          <ApexChart />
        </Box>
        <Box>
          <BasicTable />
        </Box>

        <Box>
          <LineChart />
        </Box>
      </Box>
    </Box>
  );
};

export default TokensPage;
