import { Box, CardMedia } from "@mui/material";
import React from "react";
import GifBGImage from "../../Assets/images/gifBgImage.png";
import Carousel from "react-elastic-carousel";
import ArrowForwardIosIcon from "@mui/icons-material/ArrowForwardIos";
import ArrowBackIosNewIcon from "@mui/icons-material/ArrowBackIosNew";

// Import Swiper styles
import "swiper/css";
import "swiper/css/free-mode";
import "swiper/css/pagination";

import "./styles.css";

// import required modules
import theme from "../../theme/theme";

const imagesList = [
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404455/Ghostface_Gif_seweoo.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404447/Freddy_pose_nhyktu.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404481/Jason_pose3_sg7srs.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404438/Chucky_Pose_gfmgle.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404496/Leather-face_pose_xhi1aa.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404492/mike-myers_pose_rusioj.gif",
  },
  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404497/Pennywise_pose_ru9cro.gif",
  },

  {
    src: "https://res.cloudinary.com/sheikhumar/image/upload/v1702404514/Pinhead_pose1_2_fir9re.gif",
  },
];
const breakPointsdeal = [
  { width: 320, itemsToShow: 2 },
  { width: 480, itemsToShow: 3 },
  { width: 640, itemsToShow: 4 },
  { width: 900, itemsToShow: 6 },
  // { width: 1200, itemsToShow: 2 },
];

const CharactersSlider = () => {
  return (
    <>
      {/* <Swiper
        slidesPerView={6}
        breakpoints={{
          // when window width is >= 320px
          320: {
            slidesPerView: 2,
            spaceBetween: 10,
          },
          // when window width is >= 480px
          480: {
            slidesPerView: 3,
            spaceBetween: 20,
          },
          // when window width is >= 640px
          640: {
            slidesPerView: 4,
            spaceBetween: 30,
          },
          900: {
            slidesPerView: 6,
            spaceBetween: 40,
          },
        }}
        spaceBetween={30}
        freeMode={true}
        navigation={true}
        // navigation={{
        //   prevEl: '.swiper-button-prev',
        //   nextEl: '.swiper-button-next',
        // }}
        // navigation={{    // Enable navigation
        //   nextEl: '.swiper-button-next', // CSS class for the next arrow
        //   prevEl: '.swiper-button-prev'  // CSS class for the previous arrow
        // }}
        pagination={false}
        modules={[FreeMode, Pagination, Navigation]}
        className="mySwiper"
      >
        {imagesList.map((image, index) => {
          return (
            <SwiperSlide>
              <Box
                key={index}
                sx={{
                  backgroundImage: `url(${GifBGImage})`,
                  backgroundRepeat: "no-repeat",
                  backgroundPosition: "center",
                  cursor: "pointer",
                  width: "200px",
                }}
              >
                <CardMedia
                  component={"img"}
                  src={image?.src}
                  sx={{
                    filter: "brightness(90%)",
                    height: "210px",
                  }}
                />
              </Box>
            </SwiperSlide>
          );
        })}
      </Swiper> */}

      <Carousel
        enableAutoPlay={true}
        breakPoints={breakPointsdeal}
        pagination={false}
        showArrows={true}
        transitionMs={2000}


        renderArrow={({ type, onClick }) => {
          console.log(type);
          return (
            <Box
              onClick={onClick}
              sx={{
                display: "flex",
                alignItems: "center",
                height: "100%",
              }}
            >
              {type === "PREV" ? (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    background: theme?.palette?.primary?.main,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowBackIosNewIcon
                    sx={{
                      color: "white ",
                      fontSize: "16px",
                    }}
                  />
                </Box>
              ) : (
                <Box
                  sx={{
                    width: "30px",
                    height: "30px",
                    background: theme?.palette?.primary?.main,
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    borderRadius: "50px",
                    cursor: "pointer",
                  }}
                >
                  <ArrowForwardIosIcon
                    sx={{
                      color: "white ",
                      fontSize: "16px",
                    }}
                  />{" "}
                </Box>
              )}
            </Box>
          );
        }}
      >
        {imagesList.map((image, index) => {
          return (
            <Box
              key={index}
              sx={{
                display: "flex",
                width: "100%",
                color: "#fff",
                padding: "10px 10px",
              }}
            >
               {/* <div className={"imageWrapper"}>
                <div className={"overlay"} /> */}
              <SliderCard image={image} />
              {/* </div> */}
            </Box>
          );
        })}
      </Carousel>
    </>
  );
};
export default CharactersSlider;

const SliderCard = ({ image }) => {
  return (
    <Box
      sx={{
        backgroundImage: `url(${GifBGImage})`,
        backgroundRepeat: "no-repeat",
        backgroundPosition: "center",
        cursor: "pointer",
        width: "200px",
      }}
    >
      <CardMedia
        component={"img"}
        src={image?.src}
        draggable="false" 
        sx={{
          filter: "brightness(90%)",
          height: "210px",
        }}
      />
    </Box>
  );
};
