import { Box, IconButton, Link, Typography } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React, { useState } from "react";
import { useLocation, useNavigate } from "react-router";
import MenuIcon from "@mui/icons-material/Menu";
import HeaderDrawer from "../Drawer/Drawer";
import Logo from "../../Assets/logos/Logo.png";
import Discord from "../../Assets/Icons/Discord.png";
import Twitter from "../../Assets/Icons/Twitter.png";
import WhitePaperButton from "../Buttons/WhitePaperButton";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      display: "flex",
      justifyContent: "center",
      backgroundColor: "rgba(0, 0, 0, 0.5)",
    },
    HeaderContainer: {
      display: "flex",
      justifyContent: "space-between",
      padding: "0px 20px ",
      alignItems: "center",
      width: "100%",
      gap: "20px",
      maxWidth: "90%",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
    LinkContainer: {
      display: "flex",
      gap: "50px",
      cursor: "pointer",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },

    NavLinksSection: {
      paddingTop: "10px",
      display: "flex",
      gap: "50px",
      alignItems: "center",
      whiteSpace: "nowrap",
    },
    active: {
      color: theme.palette.primary.main,
      fontWeight: "bold",
    },
    linkStyles: {
      color: theme.palette.text.main,
      fontWeight: "bolder",
    },
    hamburgerIcon: {
      display: "none",
      [theme.breakpoints.down("lg")]: {
        display: "block",
      },
    },
    iconsContainer: {
      display: "flex",
      gap: "20px",
      alignItems: "center",
      [theme.breakpoints.down("lg")]: {
        display: "none",
      },
    },
  };
});

const navLinks = [
  {
    title: "Home",
    selected: false,
    link: "/",
  },
  {
    title: "About",
    selected: false,
    link: "/",
  },
  {
    title: "Token",
    selected: false,
    link: "/tokens",
  },
  {
    title: "Characters",
    selected: false,
    link: "/",
  },
  {
    title: "Team",
    selected: false,
    link: "/",
  },
];

const Header = () => {
  const [state, setstate] = useState(navLinks);
  const {
    container,
    HeaderContainer,
    LinkContainer,
    NavLinksSection,
    active,
    linkStyles,
    hamburgerIcon,
    iconsContainer,
  } = useStyle();
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();
  const handleClick = (obj) => {
    let val = JSON.parse(JSON.stringify(state));
    let activelinks = val?.map((m) => {
      if (m.link === obj?.link) {
        m.selected = true;
      } else {
        m.selected = false;
      }
      return m;
    });
    console.log(activelinks);
    setstate(activelinks);
    navigate(obj.link);
  };

  const handelClick = ()=>{
    navigate("/whitepaper")
  }

  return (
    <Box className={container}>
      <Box
        className={HeaderContainer}
        sx={{
          flexDirection: "row",
          justifyContent: "space-between",
        }}
      >
        <Box className={NavLinksSection}>
          <img
            src={Logo}
            alt=""
            height={"72px"}
            onClick={() => navigate("/")}
            style={{
              cursor: "pointer",
            }}
          />
        </Box>
        <Box
          sx={{
            width: "100%",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <Box className={LinkContainer}>
            {state?.map((link, i) => {
              return (
                <Typography
                  className={link.selected ? active : linkStyles}
                  key={i}
                  sx={{
                    color: (theme) => theme.palette.white.main,
                    fontWeight: "medium",
                    fontSize: "18px",
                    fontFamily:"RoadRage",
                    "&:hover": {
                      color: (theme) => theme.palette.primary.main,
                    },
                  }}
                  onClick={() => {
                    handleClick(link);
                  }}
                >
                  {link.title}
                </Typography>
              );
            })}
          </Box>
        </Box>

        <Box className={iconsContainer}>
          <Box
            sx={{
              paddingTop: "10px",
            }}
          >
            <Link
              // href={HeaderContent?.socialLinks?.facebook}
              target={"_blank"}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={Discord} alt="" width={"24px"} />
            </Link>
          </Box>
          <Box
            sx={{
              paddingTop: "10px",
            }}
          >
            <Link
              // href={HeaderContent?.socialLinks?.twitter}
              target={"_blank"}
              style={{
                cursor: "pointer",
              }}
            >
              <img src={Twitter} alt="" width={"24px"} />
            </Link>
          </Box>
          <WhitePaperButton title={"WhitePaper"} handelClick={handelClick} />
        </Box>

        <Box className={hamburgerIcon}>
          <IconButton onClick={() => setOpen(true)}>
            <MenuIcon color="primary" sx={{ fontSize: "25px" }} />
          </IconButton>
        </Box>
      </Box>

      <HeaderDrawer open={open} setOpen={setOpen} list={state} />
    </Box>
  );
};

export default Header;
