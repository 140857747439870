import { Box } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import pdf from "../../Assets/whiteppaaper.pdf";
import { Document, Page } from "react-pdf";
const useStyle = makeStyles((theme) => {
  return {
    container: {},
    LandingSection: {
      display: "flex",
      flexDirection: "column",
      gap: "40px",
    },
  };
});

const WhitePaper = () => {
  const { container, LandingSection } = useStyle();
  const [numPages, setNumPages] = React.useState(null);
  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
  }
  return (
    <Box className={container}>
      <Box className={LandingSection}>
        <Box >
         
          <iframe
        title="PDF Viewer"
        src={pdf}
        height="100%"
        frameBorder="0"
        allowFullScreen
        style={{
          width:"100vw",
          height:"100vh",
          overflow:"hidden",
          scrollbarWidth:0
        }}
      ></iframe>
        </Box>
      </Box>
    </Box>
  );
};

export default WhitePaper;
