import LandingPage from "../../Pages/LandingPage/LandingPage";
import MainPage from "../../Pages/MainPage/MainPage";
import TokensPage from "../../Pages/TokensPage/TokensPage";
import WhitePaper from "../../Pages/WhitePaper/WhitePaper";

export const Paths =[
    
    {
        path: "/",
        component: <MainPage ActivePage={<LandingPage />} />,
      },
      {
        path: "/tokens",
        component: <MainPage ActivePage={<TokensPage />} />,
      },
      {
        path: "/whitepaper",
        component: <WhitePaper />,
      },
      
   
]