import { Box, CardMedia } from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import backgroundImage from "../../../Assets/images/BackgroundImage.jpg";
import GameCharacter from "../../../Assets/images/GameCharacter2.png";
import Logo from "../../../Assets/logos/Logo.png";
const useStyle = makeStyles((theme) => {
  return {
    container: {
      // height: "100vh",
      // paddingBottom:"50px"
    },
    subContainer: {
      display: "flex",
      justifyContent: "center",
      // position: "relative",
      // backgroundImage: `url(${backgroundImage})`,
      // backgroundPosition: "center",
      // backgroundSize: "cover",
      // backgroundRepeat: "no-repeat",
      // backgroundAttachment: "fixed",
      // height: "100vh",
      height: "fit-content",
      // maxHeight:"900px"
    },
  };
});

const HeroSection = () => {
  const { container, subContainer } = useStyle();

  return (
    <Box className={container}>
      <Box className={subContainer}>

      <CardMedia
                component={"video"}
                loop={true}
                autoPlay={true}
                muted
                // controls
                src={"https://res.cloudinary.com/sheikhumar/video/upload/v1702404271/video2_peqvep.mp4"}
                sx={{
                  filter: "brightness(70%)",
                  borderRadius: "10px",
                  // boxShadow: "0px 1px 5px 4px rgba(89,2,2,0.99)",
                  margin: "0px",

                  width: "100%",
                }}
              />
        {/* <Box
          sx={{
            position: "absolute",
            bottom: "-5px",
          }}
        >

          <img
            src={GameCharacter}
            alt="jocker"
            style={{
              height: "70vh",
              width:"100vw",
              maxWidth:"325px"
            }}
          />
        </Box>
        <Box
          sx={{
            position: "absolute",
            bottom: "0px",
          }}
        >
         

          <img
            src={Logo}
            alt="jocker"
            style={{
              width: "100vw",
              maxWidth: "350px",
            }}
          />
      
        </Box> */}
      </Box>
    </Box>
  );
};

export default HeroSection;
