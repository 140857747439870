import {
  Accordion,
  AccordionSummary,
  Box,
  CardMedia,
  Typography,
  AccordionDetails,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import React from "react";
import Blood from "../../../Assets/images/Blood.png";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import theme from "../../../theme/theme";

const useStyle = makeStyles(() => {
  return {
    container: {
      padding: "20px",
      display: "flex",
      justifyContent: "center",
    },
    subContainer: {
      display: "flex",
      flexDirection: "column",
      gap: "50px",
      width: "100%",
      maxWidth: "90%",
      padding: "20px 0px",
      [theme.breakpoints.down("sm")]: {
        maxWidth: "100%",
      },
    },
  };
});

const accordianData = [
  {
    title: "When is BloodBath launching?",
  },
  {
    title: "Can I play Bloodbath for free?",
  },
  {
    title: "Where can I buy $BLOOD?",
  },
  {
    title: "How do tournaments work?",
  },
];

const FAQSection = () => {
  const { container, subContainer } = useStyle();
  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  return (
    <Box className={container}>
      <Box className={subContainer}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            [theme.breakpoints.down("sm")]: {
              flexDirection: "column",
            },
          }}
        >
          <Box>
            <CardMedia
              component={"img"}
              loop
              muted
              controls
              src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404514/Pinhead_pose1_2_fir9re.gif"}
              sx={{
                filter: "brightness(90%)",
                height: "300px",
                width: "100%",
                maxWidth: "300px",
              }}
            />
          </Box>
          <Box
            sx={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              paddingTop: "150px",
            }}
          >
            <Box
              sx={{
                maxWidth: "400px",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  backgroundImage: `url(${Blood})`,
                  backgroundPositionX: "0%",
                  backgroundSize: "contain",
                  backgroundRepeat: "no-repeat",
                  height: "125px",
                  display: "flex",
                  alignItems: "start",
                  [theme.breakpoints.down("sm")]: {
                    height: "125px",
                  },
                }}
              >
                <Typography
                  sx={{
                    paddingLeft: "50px",
                    paddingTop: "45px",
                    textAlign: "center",
                    lineHeight: "50px",
                    fontSize: "35px",
                    fontFamily: "RoadRage",
                    color: (theme) => theme.palette.white.main,
                    zIndex: 99,
                    [theme.breakpoints.down("sm")]: {
                      fontSize: "25px",
                      paddingTop: "40px",
                      paddingLeft: "60px",
                    },
                  }}
                >
                  Frequestly Asked Questions&#60;
                </Typography>
              </Box>
            </Box>
            <Box
              sx={{
                display: "flex",
                justifyContent: "center",
                width: "100%",
                padding: "25px 0px",
              }}
            >
              <Box
                sx={{
                  width: "100%",
                  maxWidth: "500px",
                }}
              >
                <Box>
                  {accordianData?.map((data, i) => {
                    return (
                      <Accordion
                        disableGutters={true}
                        key={i}
                        expanded={expanded === i}
                        onChange={handleChange(i)}
                        sx={{
                          backgroundColor: "transparent",
                          borderBottom: "1px solid white",
                        }}
                      >
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon
                              sx={{
                                color: (theme) =>
                                  expanded === i
                                    ? theme.palette.primary.main
                                    : theme.palette.white.main,
                                "&:hover": {
                                  color: (theme) => theme.palette.primary.main,
                                },
                              }}
                            />
                          }
                          aria-controls="panel1bh-content"
                          id="panel1bh-header"
                        >
                          <Typography
                            sx={{
                              width: "100%",
                              flexShrink: 0,
                              fontSize: "18px",
                              color:
                                expanded === i
                                  ? theme.palette.primary.main
                                  : theme.palette.white.main,
                              "&:hover": {
                                color: (theme) => theme.palette.primary.main,
                              },
                            }}
                          >
                            {i + 1}. {data?.title}
                          </Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <Typography
                            sx={{
                              color: (theme) => theme.palette.white.main,
                              fontSize: "16px",
                            }}
                          >
                            {data?.title ===
                            "Can I play Bloodbath for free?" ? (
                              <Typography
                                sx={{
                                  color: (theme) => theme.palette.white.main,
                                  fontSize: "16px",
                                }}
                              >
                                Absolutely! "Bloodbath: Killer vs. Killer"
                                offers a practice mode that allows you to
                                experience the game without any cost. In this
                                mode, you can engage in battles using simpler
                                moves and basic weapons, giving you a taste of
                                the adrenaline-pumping combat dynamics.
                                <br />
                                <br />
                                However, to fully unlock the immersive and
                                exhilarating experience that "Bloodbath" has to
                                offer, including access to a wide range of
                                powerful abilities, diverse champions, and
                                advanced gear, you'll need to utilize Blood
                                tokens. These tokens not only enhance your
                                gameplay but also open doors to exciting
                                rewards, upgrades, and customization options
                                that truly set you apart in the brutal world of
                                PvP combat.
                                <br />
                                <br />
                                So while you can enjoy a limited experience in
                                the practice mode without spending, embracing
                                the full potential of "Bloodbath: Killer vs.
                                Killer" and immersing yourself in the
                                blood-soaked battles will be most satisfying
                                through the use of Blood tokens.
                                <br />
                                <br />
                                Prepare to harness the power of Blood tokens and
                                unleash your ferocity on the battlefield!
                                <br />
                              </Typography>
                            ) : data?.title === "Where can I buy $BLOOD?" ? (
                              <Typography sx={{
                                color: (theme) => theme.palette.white.main,
                                fontSize: "16px",
                              }}>
                                You can acquire Blood tokens, our valuable
                                in-game currency with real-world value, through
                                the Uniswap decentralized exchange (DEX). Simply
                                visit Uniswap and follow the straightforward
                                steps to secure your Blood tokens and gain an
                                early advantage in "Bloodbath: Killer vs.
                                Killer."
                                <br />
                                <br />
                                Moreover, we're excited to announce that in the
                                near future, Blood tokens will also be available
                                on other well-established Centralized Exchange
                                (CEX) platforms. This expansion will offer you
                                additional options for obtaining Blood tokens,
                                ensuring accessibility and convenience for all
                                players.
                                <br />
                                <br />
                                Stay tuned for updates on our official channels
                                regarding the expansion of Blood token
                                availability. Get ready to dive into the world
                                of ruthless battles and reap the rewards that
                                Blood tokens bring to the fray!
                                <br />
                                <br />
                                For more information, keep an eye on our website
                                and social media channels.
                                <br />
                              </Typography>
                            ) : data?.title ===
                              "When is BloodBath launching?" ? (
                              <Typography sx={{
                                color: (theme) => theme.palette.white.main,
                                fontSize: "16px",
                              }}>
                                The eagerly awaited launch of our game,
                                "Bloodbath: Killer vs. Killer," is planned to
                                commence shortly after the conclusion of the
                                Blood Token Presale. We understand the
                                anticipation surrounding the game's release, and
                                we're excited to bring you an immersive and
                                captivating gaming experience. <br /> <br />
                                The Blood Token Presale is a pivotal phase that
                                allows early supporters to acquire Blood tokens,
                                our in-game currency that holds real-world
                                value. This presale not only provides players
                                with an opportunity to secure a valuable head
                                start but also plays a vital role in ensuring
                                the game's successful launch by supporting its
                                development and infrastructure. <br /> <br />
                                Our team is committed to delivering a polished
                                and thrilling gameplay experience that lives up
                                to your expectations. The time following the
                                Blood Token Presale will be dedicated to
                                finalizing any necessary optimizations,
                                balancing mechanics, and addressing feedback
                                from our community. This meticulous approach
                                guarantees that when the game launches, it will
                                be a seamless and exhilarating journey into the
                                realm of visceral combat and fierce competition.{" "}
                                <br /> <br />
                                We appreciate your enthusiasm and patience as we
                                work towards delivering a game that's worth the
                                wait. Keep an eye on our official channels for
                                updates on the game's development progress,
                                including the exact launch date. We're grateful
                                for your support and can't wait to unleash the
                                mayhem of "Bloodbath: Killer vs. Killer" upon
                                the world! <br /> <br />
                                Thank you for being a part of this thrilling
                                journey. <br /> <br />
                                Best regards, <br />
                                The Bloodbath Team
                                <br />
                              </Typography>
                            ) : data?.title === "How do tournaments work?" ? (
                              <Typography sx={{
                                color: (theme) => theme.palette.white.main,
                                fontSize: "16px",
                              }}>
                                he "Bloodbath Brawl: Carnage Championship" is a
                                high-stakes tournament within the world of
                                "Bloodbath: Killer vs. Killer." It offers
                                players a chance to showcase their combat
                                prowess and earn a massive haul of "Blood"
                                tokens. This brutal tournament unfolds in
                                stages, leading up to a climactic showdown that
                                promises grandeur and gruesome glory.
                                <br />
                                <br />
                               <Typography sx={{
                                color: theme?.palette?.primary?.main,
                                fontSize: "18px ",
                                fontWeight:"600"

                               }}> Tournament Structure:</Typography>
                                Preliminary Rounds: Bloodshed Rumble
                                Participants engage in a series of quick-paced,
                                one-on-one battles. Victories grant participants
                                a set amount of Blood tokens based on
                                performance. The top performers move on to the
                                next stage.
                                <br />
                                <br />
                              
                                <Typography sx={{
                                color: theme?.palette?.primary?.main,
                                fontSize: "18px ",
                                fontWeight:"600"

                               }}>   Quarterfinals: Slaughter Showdown</Typography>
                                Quarterfinalists are pitted against each other
                                in elimination-style matches. The intensity
                                amplifies as participants vie for advancement.
                                Blood tokens awarded increase for those who
                                reach the semifinals.
                                <br />
                                <br />
                             
                                <Typography sx={{
                                color: theme?.palette?.primary?.main,
                                fontSize: "18px ",
                                fontWeight:"600"

                               }}>    Semifinals: Havoc Semifusion</Typography>
                                Semifinal battles bring the remaining contenders
                                into a fierce confrontation. Token rewards surge
                                for those who advance to the finals.
                                <br />
                                <br />
                                
                                <Typography sx={{
                                color: theme?.palette?.primary?.main,
                                fontSize: "18px ",
                                fontWeight:"600"

                               }}> Finals: Cataclysm Clash</Typography>
                                The ultimate showdown takes place in a grand
                                arena. Finalists compete in a best-of-three
                                series to determine the victor. Enormous Blood
                                token rewards await the champion.
                                <br />
                                <br />
                               
                                <Typography sx={{
                                color: theme?.palette?.primary?.main,
                                fontSize: "18px ",
                                fontWeight:"600"

                               }}>  Unique Tournament Features:</Typography>
                                Ultimate Bloodbath Round: As a special twist, a
                                sudden-death round may be introduced in the
                                finals, where each finalist has only one life.
                                The last standing contender wins an extra-large
                                Blood token prize. <br />
                                <br />
                                <br />
                              </Typography>
                            ) : null}
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    );
                  })}
                </Box>
              </Box>
            </Box>
          </Box>
          <Box
            sx={{
              display: "flex",
              alignItems: "flex-end",
              height: "100%",
            }}
          >
            <CardMedia
              component={"img"}
              loop
              muted
              controls
              src={"https://res.cloudinary.com/sheikhumar/image/upload/v1702404438/Chucky_Pose_gfmgle.gif"}
              sx={{
                filter: "brightness(90%)",
                height: "300px",
                width: "100%",
                maxWidth: "300px",
              }}
            />
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default FAQSection;
